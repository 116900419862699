/* BASIC APP CONSTANTS */
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const APP_COOKIE_NAME = "APP_COOKIE_NAME";
export const USER_COOKIE_NAME = "USER_COOKIE_NAME";
export const PASS_COOKIE_NAME = "PASS_COOKIE_NAME";
export const USER_ID_COOKIE_NAME = "USER_ID_COOKIE_NAME";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SET_SIDE_MENU_STATE = "SET_SIDE_MENU_STATE";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const SHOW_ACTION_MENU = "SHOW_ACTION_MENU";
export const HIDE_ACTION_MENU = "HIDE_ACTION_MENU";
export const SHOW_BOTTOM_TOOLBAR = "SHOW_BOTTOM_TOOLBAR";
export const HIDE_BOTTOM_TOOLBAR = "HIDE_BOTTOM_TOOLBAR";
export const SET_USER_LIST = "SET_USER_LIST";
export const EDIT_DISABLED_BUTTONS = "EDIT_DISABLED_BUTTONS";
export const SET_LOGS_LIST = "SET_LOGS_LIST";
export const THEME = "THEME";

/* <---- BASIC APP CONSTANTS ----> */

/* SOCKET CONSTANTS */
export const CONNECTED = "connected";
export const DISCONNECTED = "disconnected";
/* <---- SOCKET CONSTANTS ----> */

export const SET_CONFIGURATION = "SET_CONFIGURATION";
/* <---- CONFIGURATION ----> */

/* USER */
export const SET_PROFILE = "SET_PROFILE";
/* <---- USER ----> */

/* USERS */
export const ADD_NEW_USER = "ADD_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const OPEN_USERS_FORM = "OPEN_USERS_FORM";
export const CLOSE_USERS_FORM = "CLOSE_USERS_FORM";
export const EDIT_SELECTED_USER = "EDIT_SELECTED_USER";
export const USERS_FORM_TOOGLE_LOADING = "USERS_FORM_TOOGLE_LOADING";
/* <---- USERS ----> */

/* EQUIPMENT */
export const SET_EQUIPMENT_LIST = "SET_EQUIPMENT_LIST";
export const ADD_NEW_EQUIPMENT = "ADD_NEW_EQUIPMENT";
export const UPDATE_EQUIPMENT = "UPDATE_EQUIPMENT";
export const DELETE_EQUIPMENT = "DELETE_EQUIPMENT";
export const OPEN_EQUIPMENT_FORM = "OPEN_EQUIPMENT_FORM";
export const CLOSE_EQUIPMENT_FORM = "CLOSE_EQUIPMENT_FORM";
export const EDIT_SELECTED_EQUIPMENT = "EDIT_SELECTED_EQUIPMENT";
export const EQUIPMENT_FORM_TOOGLE_LOADING = "EQUIPMENT_FORM_TOOGLE_LOADING";
/* <---- EQUIPMENT ----> */

/* MAINTENANCE */
export const SET_MAINTENANCE_LIST = "SET_MAINTENANCE_LIST";
export const ADD_NEW_MAINTENANCE = "ADD_NEW_MAINTENANCE";
export const UPDATE_MAINTENANCE = "UPDATE_MAINTENANCE";
export const DELETE_MAINTENANCE = "DELETE_MAINTENANCE";
export const OPEN_MAINTENANCE_FORM = "OPEN_MAINTENANCE_FORM";
export const CLOSE_MAINTENANCE_FORM = "CLOSE_MAINTENANCE_FORM";
export const EDIT_SELECTED_MAINTENANCE = "EDIT_SELECTED_MAINTENANCE";
export const MAINTENANCE_FORM_TOOGLE_LOADING = "MAINTENANCE_FORM_TOOGLE_LOADING";
/* <---- MAINTENANCE ----> */

/* INSTALATION */
export const SET_INSTALATION_LIST = "SET_INSTALATION_LIST";
export const ADD_NEW_INSTALATION = "ADD_NEW_INSTALATION";
export const UPDATE_INSTALATION = "UPDATE_INSTALATION";
export const DELETE_INSTALATION = "DELETE_INSTALATION";
export const OPEN_INSTALATION_FORM = "OPEN_INSTALATION_FORM";
export const CLOSE_INSTALATION_FORM = "CLOSE_INSTALATION_FORM";
export const EDIT_SELECTED_INSTALATION = "EDIT_SELECTED_INSTALATION";
export const INSTALATION_FORM_TOOGLE_LOADING = "INSTALATION_FORM_TOOGLE_LOADING";
/* <---- INSTALATION ----> */

/* CONFIGURATION */
export const SET_CONFIGURATION_LIST = "SET_CONFIGURATION_LIST";
export const ADD_NEW_CONFIGURATION = "ADD_NEW_CONFIGURATION";
export const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";
export const OPEN_CONFIGURATION_FORM = "OPEN_CONFIGURATION_FORM";
export const CLOSE_CONFIGURATION_FORM = "CLOSE_CONFIGURATION_FORM";
export const EDIT_SELECTED_CONFIGURATION = "EDIT_SELECTED_CONFIGURATION";
export const CONFIGURATION_FORM_TOOGLE_LOADING = "CONFIGURATION_FORM_TOOGLE_LOADING";
/* <---- CONFIGURATION ----> */

/* BUSINESS */
export const SET_BUSINESS_LIST = "SET_BUSINESS_LIST";
export const ADD_NEW_BUSINESS = "ADD_NEW_BUSINESS";
export const UPDATE_BUSINESS = "UPDATE_BUSINESS";
export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const OPEN_BUSINESS_FORM = "OPEN_BUSINESS_FORM";
export const CLOSE_BUSINESS_FORM = "CLOSE_BUSINESS_FORM";
export const EDIT_SELECTED_BUSINESS = "EDIT_SELECTED_BUSINESS";
export const BUSINESS_FORM_TOOGLE_LOADING = "BUSINESS_FORM_TOOGLE_LOADING";
/* <---- BUSINESS ----> */

/* ROUTES */
export const SET_ALL_ROUTES = "SET_ALL_ROUTES";
export const UPDATE_ROUTE = "UPDATE_ROUTE";
/* <---- ROUTES ----> */

/* ALERT */
export const SET_ALERT_LIST = "SET_ALERT_LIST";
export const ADD_NEW_ALERT = "ADD_NEW_ALERT";
export const UPDATE_ALERT = "UPDATE_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const OPEN_ALERT_FORM = "OPEN_ALERT_FORM";
export const CLOSE_ALERT_FORM = "CLOSE_ALERT_FORM";
export const EDIT_SELECTED_ALERT = "EDIT_SELECTED_ALERT";
export const ALERT_FORM_TOOGLE_LOADING = "ALERT_FORM_TOOGLE_LOADING";
/* <---- ALERT ----> */
