export const API = {
  equipments: {
    create: `/equipment`,
    edit: `/equipment/`,
    delete: `/equipment/`,
    list: `/equipment`,
  },
  maintenances: {
    create: `/maintenance`,
    edit: `/maintenance/`,
    delete: `/maintenance/`,
    list: `/maintenance`,
    mail: `/mail`,
  },
  instalations: {
    create: `/instalation`,
    edit: `/instalation/`,
    delete: `/instalation/`,
    list: `/instalation`,
  },
  configurations: {
    create: `/configuration`,
    edit: `/configuration/`,
    delete: `/configuration/`,
    list: `/configuration`,
  },
  businesss: {
    create: `/business`,
    edit: `/business/`,
    delete: `/business/`,
    list: `/business`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
