import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  businesss,
  businesssForm,
  configurations,
  configurationsForm,
  instalations,
  instalationsForm,
  maintenances,
  maintenancesForm,
  equipments,
  equipmentsForm,
} from "./Reducers";

export default combineReducers({
  equipments,
  equipmentsForm,
  maintenances,
  maintenancesForm,
  instalations,
  instalationsForm,
  configurations,
  configurationsForm,
  businesss,
  businesssForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
