import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_INSTALATION,
    DELETE_INSTALATION,
    INSTALATION_FORM_TOOGLE_LOADING,
    SET_INSTALATION_LIST,
    SHOW_NOTIFICATION,
    UPDATE_INSTALATION,
} from "../constants";
import { formatInstalations } from "./settingsActionsUtils";

/* INSTALATION LIST */
export const fetchInstalations = () => {
    return async (dispatch, useState) => {
        const response = await axios
            .get(API.instalations.list)
            .then((response) => {
                const instalations = formatInstalations(response.data, useState());
                dispatch({
                    type: SET_INSTALATION_LIST,
                    payload: keyBy(instalations, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// INSTALATIONS
export const createInstalation = (newInstalation) => {
    return async (dispatch, useState) => {
        dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.instalations.create, newInstalation)
            .then((response) => {
                const instalation = formatInstalations(response.data, useState());
                dispatch({ type: ADD_NEW_INSTALATION, payload: instalation });
                dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Instalación creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateInstalation = (updatedInstalation) => {
    return async (dispatch, useState) => {
        dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.instalations.edit}${updatedInstalation && updatedInstalation._id}`, updatedInstalation)
            .then((response) => {
                const instalation = formatInstalations(response.data, useState());
                dispatch({ type: UPDATE_INSTALATION, payload: instalation });
                dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Instalación actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteInstalations = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.instalations.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_INSTALATION, payload: Ids });
                dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Instalación eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: INSTALATION_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
