import moment from "moment";
import { isArray, isObject } from "lodash";

export const formatEquipments = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      dateOfManufacture: data.dateOfManufacture && moment(data.dateOfManufacture),
      dateOfLastReview: data.dateOfLastReview && moment(data.dateOfLastReview),
      dateOfTheLast: data.dateOfTheLast && moment(data.dateOfTheLast),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      dateOfManufacture: data.dateOfManufacture && moment(data.dateOfManufacture),
      dateOfLastReview: data.dateOfLastReview && moment(data.dateOfLastReview),
      dateOfTheLast: data.dateOfTheLast && moment(data.dateOfTheLast),
    };
  }
};
export const formatMaintenances = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
      instalationDateInstalation: data.instalationDateInstalation && moment(data.instalationDateInstalation),
      instalationLastMaintenance: data.instalationLastMaintenance && moment(data.instalationLastMaintenance),
      date: data.date && moment(data.date),
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
      instalationDateInstalation: data.instalationDateInstalation && moment(data.instalationDateInstalation),
      instalationLastMaintenance: data.instalationLastMaintenance && moment(data.instalationLastMaintenance),
      date: data.date && moment(data.date),
      start: data.start && moment(data.start),
      end: data.end && moment(data.end),
    };
  }
};
export const formatInstalations = (data, state) => {
  if (!data) {
    return [];
  }
  if (!state && state.businesss) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${state.businesss[data.business] && state.businesss[data.business].label}`,
      value: data._id,
      lastMaintenance: data.lastMaintenance && moment(data.lastMaintenance),
      dateInstalation: data.dateInstalation && moment(data.dateInstalation),
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${state.businesss[data.business] && state.businesss[data.business].label}`,
      lastMaintenance: data.lastMaintenance && moment(data.lastMaintenance),
      dateInstalation: data.dateInstalation && moment(data.dateInstalation),
    };
  }
};
export const formatConfigurations = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatBusinesss = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name}`,
    };
  }
};
export const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.name} ${data.surname}`,
      value: data._id
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.name} ${data.surname}`
    };
  }
};

export const formatAlerts = (data) => {
  if (!data) {
    return [];
  }
  if (isArray(data)) {
    return data.map((data) => ({
      ...data,
      label: `${data.title}`,
      value: data._id,
    }));
  }
  if (isObject(data)) {
    return {
      ...data,
      value: data._id,
      label: `${data.title}`,
    };
  }
};
